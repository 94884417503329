<template>
  <div class="tw-p-10">
    <img
      src="@/assets/error-imagem.png"
      class="tw-mx-auto tw-w-2/3 sm:tw-w-1/3"
    />
    <div class="tw-text-center tw-p-5 tw-font-bold">Pagina não encontrada</div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
